<script setup>
import { computed } from 'vue'

/**
 * The component's props.
 */
const props = defineProps({
    user: {
        type: Object,
        required: true,
    },

    /**
     * The logout callback function.
     */
    onLogout: {
        type: Function,
        default: () => {},
    },
})

/**
 * The user's initials.
 *
 * @return  {String}
 */
const initials = computed(() => {
    if (props.user.first_name && props.user.last_name) {
        return `${props.user.first_name.charAt(0)}${props.user.last_name.charAt(
            0
        )}`
    }

    return ''
})
</script>

<template>
    <v-btn icon="far fa-map" href="/mapping/" target="_blank"> </v-btn>
    <v-btn
        icon="far fa-gear"
        href="/index.cfm?module=settings&package=&template="
    />
    <v-btn icon size="32" class="ms-2">
        <v-avatar color="secondary" size="32">
            <span v-if="initials">
                {{ initials }}
            </span>
            <v-icon v-else icon="fas fa-user" size="small"></v-icon>
            <v-menu activator="parent" location="bottom">
                <v-card min-width="200" class="mt-2 elevation-2" width="auto">
                    <v-list>
                        <div class="text-caption px-2">
                            <div class="text-grey-darken-2">Welcome!</div>
                        </div>
                        <v-list-item>
                            <router-link
                                :to="{
                                    name: 'root',
                                    query: {
                                        module: 'user',
                                        package: '',
                                        template: 'profile',
                                    },
                                }"
                            >
                                My Account
                            </router-link>
                        </v-list-item>
                        <v-list-item
                            href="https://help.boltsystem.com/knowledge-base"
                            target="_blank"
                        >
                            Knowledge Base
                        </v-list-item>
                        <v-list-item
                            href="https://support.boltsystem.com/tickets-view"
                            target="_blank"
                        >
                            Support Portal
                        </v-list-item>
                        <v-list-item
                            href="/index.cfm?module=authentication&template=frmLogin"
                            @click.prevent="onLogout"
                        >
                            Logout
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
        </v-avatar>
    </v-btn>
</template>
