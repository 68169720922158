import { createRouter, createWebHistory } from 'vue-router'

import { useAuthStore } from '@/stores/Auth'
import BoltRouter from '@/components/BoltRouter.vue'

/**
 * Handle route error and reload the page.
 *
 * @param {Object} error
 * @param {Object} to
 *
 * @return {void}
 */
const handleRouteError = (error, to) => {
    if (!to?.fullPath) {
        return window.location.reload()
    }

    window.location = to.fullPath
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            redirect: {
                path: '/index.cfm',
                query: {
                    module: 'authentication',
                    template: 'frmLogin',
                    package: '',
                },
            },
            children: [
                {
                    path: '/index.cfm',
                    component: BoltRouter,
                    name: 'root',
                },
            ],
        },
    ],
})
router.beforeEach(async (to) => {
    const store = useAuthStore()

    if (!store.isAuthenticated && to.query?.template !== 'frmLogin') {
        return {
            path: 'index.cfm',
            query: {
                module: 'authentication',
                template: 'frmLogin',
                package: '',
            },
        }
    }

    if (to.query?.template === 'frmLogin' && store.isAuthenticated) {
        return {
            path: 'index.cfm',
            query: {
                module: 'dispatch',
                template: 'dspDispatch',
                package: '',
            },
        }
    }

    return true
})

router.onError((error, to) => {
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('Importing a module script failed')
    ) {
        handleRouteError(error, to)
    }
})

export default router
